import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
/** @jsx jsx */
import { jsx, useThemeUI, Styled } from 'theme-ui';

const PromoImage = ({ image, promo, alt }) => {
  return (
    <section
      sx={{
        display: 'grid',
        gridGap: 2,
        gridTemplateColumns: ['100%', '100%', '30% 70%'],
      }}
    >
      <div
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          backgroundColor: 'accent',
          p: 3,
          order: [2, 2, 1],
        }}
      >
        <span
          sx={{
            //display: 'block',
            fontSize: [4, 4, 5],
            fontStyle: 'italic',
            fontWeight: 'bold',
            color: 'background',
          }}
        >
          {promo}
        </span>
      </div>
      <Img
        alt={alt}
        fluid={image}
        sx={{ maxHeight: '400px', order: [1, 1, 2] }}
        objectFit="cover"
        objectPosition="50% 50%"
      />
    </section>
  );
};

export default PromoImage;
