import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import BlockContent from '@sanity/block-content-to-react';
import SEO from '../components/seo';

/** @jsx jsx */
import { jsx, useThemeUI, Styled } from 'theme-ui';
import serializer from '../gatsby-plugin-theme-ui/article-serializer';
import Layout from '../components/Layout';
import Container from '../components/Container';
import PromoImage from '../components/PromoImage';
import ContactForm from '../components/ContactForm';
import ServiceList from '../components/ServiceList';
import serializers from '../gatsby-plugin-theme-ui/article-serializer';

const ServicePage = ({ data: { sanityService } }) => {
  // if (sanityService.figure) {
  //   console.log('service data', sanityService);
  // }

  let image = null;
  let metaImage = null;
  let imageAlt = null;
  if (sanityService && sanityService.figure && sanityService.figure.image) {
    metaImage = {
      src: sanityService.figure.image.asset.metaImage.src,
      height: 1200,
      width: 630,
    };
    image = sanityService.figure.image.asset.fluid;
    imageAlt = sanityService.figure.alt;
  }

  const article = sanityService._rawBody;
  const headline = sanityService.headline;
  const promo = sanityService.promo;
  const description = sanityService.longExcerpt;
  const name = sanityService.name;
  const seoTitle = sanityService.seoTitle || name;

  const renderArticle = () => {
    return <BlockContent blocks={article} serializers={serializers} />;
  };

  const ArticleGrid = () => {
    return (
      <>
        <SEO title={seoTitle} description={description} metaImage={metaImage} />
        <div
          sx={{
            display: 'grid',
            gridGap: 4,
            gridTemplateColumns: ['100%', '100%', '100%', '65% 35%'],
            py: [0, 0, 4],
          }}
        >
          <article>
            <h2>{headline}</h2>
            {renderArticle()}
          </article>
          <aside
            sx={{
              backgroundColor: 'primaryShade',
              height: '100%',
              py: 5,
              px: 4,
            }}
          >
            <ContactForm style={{ py: 2 }} />
            <ServiceList style={{ mt: 5 }} />
          </aside>
        </div>
      </>
    );
  };

  const renderPromoImage = () => {
    if (!image) {
      return null;
    } else {
      return <PromoImage image={image} alt={imageAlt} promo={promo} />;
    }
  };

  return (
    <Layout>
      <SEO title={seoTitle} description={description} />
      <Container>
        <h1>{name}</h1>
        {renderPromoImage()}
        <ArticleGrid />
      </Container>
    </Layout>
  );
};

ServicePage.propTypes = {};

export default ServicePage;

export const pageQuery = graphql`
  query ServicePageByID($id: String!) {
    sanityService(id: { eq: $id }) {
      id
      longExcerpt
      seoTitle
      name
      body {
        _key
        _type
        style
        list
        _rawChildren
      }
      _rawBody
      headline
      promo
      figure {
        alt
        image {
          asset {
            fluid(maxWidth: 1500) {
              ...GatsbySanityImageFluid
            }
            metaImage: fixed(width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
  }
`;
